<template>
  <div class="main">
    <h1>Not Found!</h1>
  </div>
</template>
<script setup>

</script>
<style scoped>
.main{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1E212D;
  z-index: 1000;
}
h1{
  color: #ffa573;
}
</style>