<template>
  <div class="container">
    <div class="route-name">
      <h4>{{route.name}}</h4>
    </div>
    <div class="add">
      <div class="add-h4">
        <h3>Ad:</h3>
      </div>
      <div class="add-input">
        <input class="input-class" type="text" v-model="product.name">
      </div>
      <div class="col">
        <div class="col-item">
          <div class="add-h4 col-item">
            <h3>Marka:</h3>
          </div>
          <div class="add-input">
            <select class="input-class select-input" v-model="product.brand_id">
              <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{brand.name}}</option>
            </select>
            <font-awesome-icon @click="delSelectInput('brand')" class="input-icon" :icon="['fas', 'trash']"/>
          </div>
        </div>
        <div class="col-item">
          <div class="add-h4 col-item">
            <h3>Vergi Dilimi:</h3>
          </div>
          <div class="add-input">
            <select class="input-class select-input" v-model="product.tax_id">
              <option v-for="tax in taxes" :key="tax.id" :value="tax.id">{{tax.name}}</option>
            </select>
            <font-awesome-icon @click="delSelectInput('tax')" class="input-icon" :icon="['fas', 'trash']" />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="col-item">
          <div class="add-h4 col-item">
            <h3>Kategori:</h3>
          </div>
          <div class="add-input">
            <select class="input-class select-input" v-model="product.category_id" disabled>
              <option v-for="category in categories" :key="category.id" :value="category.id">{{category.name}}</option>
            </select>
            <font-awesome-icon @click="delSelectInput('')" class="input-icon" :icon="['fas', 'trash']" />
          </div>
        </div>
        <div class="col-item">
          <div class="add-h4 col-item">
            <h3>Alt Kategori:</h3>
          </div>
          <div class="add-input">
            <select class="input-class select-input" v-model="product.sub_category_id" disabled>
              <option v-for="sub_category in sub_categories" :key="sub_category.id" :value="sub_category.id">{{sub_category.name}}</option>
            </select>
            <font-awesome-icon @click="delSelectInput('')" class="input-icon" :icon="['fas', 'trash']" />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="col-item">
          <div class="add-h4 col-item">
            <h3>Birim Fiyat:</h3>
          </div>
          <div class="add-input">
            <input class="input-class" type="text" v-model="product.unit_price">
          </div>
        </div>
        <div class="col-item">
          <div class="add-h4 col-item">
            <h3>Transfer Ücreti:</h3>
          </div>
          <div class="add-input">
            <input class="input-class" type="text" v-model="product.transfer_fee">
          </div>
        </div>
      </div>
      <div class="add-h4">
        <h3>Açıklama:</h3>
      </div>
      <div class="add-text-area">
        <ckeditor :editor="editor" v-model="product.desc" :config="editorConfig"></ckeditor>
      </div>
      <div class="add-btn">
        <button class="btn-ekle" @click="updateData()">Güncelle</button>
      </div>
    </div>
  </div>
</template>
<script setup>
import {useRoute} from "vue-router";
import {onMounted, ref} from "vue";
import { useToast } from "vue-toastification";
import axios from "axios";
import store from "@/store";
import router from "@/router";
import { ClassicEditor, Bold, Essentials, Italic, Paragraph, Undo } from 'ckeditor5';
import CKEditor from '@ckeditor/ckeditor5-vue';
import 'ckeditor5/ckeditor5.css';

const editor = ClassicEditor,
ckeditor = CKEditor.component
const editorConfig = {
  plugins: [ Bold, Essentials, Italic, Paragraph, Undo ],
  toolbar: [ 'bold', 'italic', '|', 'undo', 'redo' ]
}
const toast = useToast();
const route = useRoute();
const product = ref({
  name:'',
  desc:'',
  category_id:null,
  sub_category_id:null,
  tax_id:null,
  brand_id:null,
  unit_price:null,
  transfer_fee:null,
})
const brands = ref()
const taxes = ref()
const categories = ref()
const sub_categories = ref()
async function getData() {
  await axios.get(`https://service.abdullahemekelektrik.com/api/v1/product/${route.params.id}`,{
    headers : {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then(response => {
    product.value = response.data
  }).catch(error => {
    toast.error('Bir Hata Oluştu', {
      position: "top-right",
      timeout: 2000,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      draggable: true,
      draggablePercent: 0.32,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false
    })
    router.push('/product');
  });
}
async function getUsedData() {
  await axios.get("https://service.abdullahemekelektrik.com/api/v1/brand?no_page",{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    brands.value = response.data
  });
  await axios.get("https://service.abdullahemekelektrik.com/api/v1/tax?no_page",{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    taxes.value = response.data
  });
  await axios.get("https://service.abdullahemekelektrik.com/api/v1/categories?no_page",{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    categories.value = response.data
  });
  await axios.get("https://service.abdullahemekelektrik.com/api/v1/sub_categories?no_page",{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    sub_categories.value = response.data
  });
}
function delSelectInput(e) {
  if (e === 'brand'){
    product.value.brand_id = null
  }
  if (e === 'tax'){
    product.value.tax_id = null
  }
  if (e === 'category'){
    product.value.category_id = null
  }
  if (e === 'sub_category'){
    product.value.sub_category_id = null
  }
}
function updateData(){
  let formData = new FormData();
  formData.append("name", product.value.name);
  formData.append("desc", product.value.desc);
  formData.append("tax_id", product.value.tax_id);
  formData.append("brand_id", product.value.brand_id);
  formData.append("unit_price", product.value.unit_price);
  formData.append("transfer_fee", product.value.transfer_fee);
  if (product.value.category_id){
    formData.append("category_id", product.value.category_id);
  }
  if (product.value.sub_category_id){
    formData.append("sub_category_id", product.value.sub_category_id);
  }
  formData.append("_method", 'PUT');
  axios.post(`https://service.abdullahemekelektrik.com/api/v1/product/${route.params.id}`,formData,{
    headers : {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then(response => {
    toast.success(response.data, {
      position: "top-right",
      timeout: 2000,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      draggable: true,
      draggablePercent: 0.32,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false
    })
    router.push('/product')
  }).catch(error => {
    toast.error('Bir Hata Oluştu', {
      position: "top-right",
      timeout: 2000,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      draggable: true,
      draggablePercent: 0.32,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false
    })
  });
}
onMounted(() => {
  getData();
  getUsedData();
});
</script>
<style scoped>
.container{
  color: black;
  height: 100%;
}
.route-name{
  display: flex;
  color: #1f3660;
  margin: 0 10px;
}
.add{
  z-index: 1000;
  margin: 0 10px;
  height: auto;
}
.add-h4{
  display: flex;
  justify-content: start;
  align-items: center;
}
.add-input{
  display: flex;
  justify-content: start;
  align-items: center;
}
.add-btn{
  display: flex;
  justify-content: end;
  align-items: center;
}
.input-class{
  width: 100%;
  height:2rem;
  text-align: center;
}
.btn-ekle{
  display: flex;
  justify-content: center;
  align-items: center;
  height:2.4rem;
  width: 5rem;
  margin: 14px 0;
  background-color: #01204E;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.select-input{
  min-height: 2.8rem;
}
.input-icon{
  box-sizing: border-box;
  margin:0 .5rem;
  cursor: pointer;
}
.add-text-area{
  box-sizing: border-box;
  width: 100%;
  height: 100%
}
.col{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.col-item{
  width: 48%;
  box-sizing: border-box;
}
@media screen and (max-width: 992px) {
  .col{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .col-item{
    width: 100%;
    box-sizing: border-box;
  }
}
</style>