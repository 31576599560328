<template>
    <div class="side-bar" v-if="windowWidth > 760 || openSidebar">
      <div class="side-bar-x" @click="openSidebar = !openSidebar" v-if="openSidebar">
        <font-awesome-icon :icon="['fas', 'xmark']" />
      </div>
      <div class="logo-side">
        <img src="https://service.abdullahemekelektrik.com/emekelektrik.jpeg" alt="">
      </div>
      <div class="main-side">
        <ul class="menu">
          <li class="menu-item">
            <router-link to="/dashboard" tag="a">Dashboard</router-link>
          </li>
          <li class="menu-item menu-drop" @click="menu_drop_item1_change">
            <a   >Ürün Yönetimi</a>
            <font-awesome-icon :icon="['fas', 'caret-down']" v-if="menu_drop_item1"/>
            <font-awesome-icon :icon="['fas', 'caret-right']" v-if="!menu_drop_item1"/>
          </li>
          <li class="menu-item menu-item-drop" v-if="menu_drop_item1">
            <router-link to="/category" tag="a"> - Kategori</router-link>
          </li>
          <li class="menu-item menu-item-drop" v-if="menu_drop_item1">
            <router-link to="/sub-category" tag="a"> - Alt Kategori</router-link>
          </li>
          <li class="menu-item menu-item-drop" v-if="menu_drop_item1">
            <router-link to="/brand" tag="a"> - Marka</router-link>
          </li>
          <li class="menu-item menu-item-drop" v-if="menu_drop_item1">
            <router-link to="/tax" tag="a"> - Vergi Dilimi</router-link>
          </li>
          <li class="menu-item menu-item-drop" v-if="menu_drop_item1">
            <router-link to="/product" tag="a"> - Ürün</router-link>
          </li>
          <li class="menu-item">
            <a  >User</a>
          </li>
          <li class="menu-item">
            <a  >Ayarlar</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="side-bar-hamburger" v-if="!openSidebar && windowWidth < 760" @click="openSidebar = !openSidebar">
      <font-awesome-icon :icon="['fas', 'bars']" />
    </div>
</template>
<script setup>
  import {ref} from "vue";
  const productRoute = ref(false);
  const sideBar = ref(false);
  const menu_drop_item1 = ref(false);
  const menu_drop_item1_change = () => {
    menu_drop_item1.value = !menu_drop_item1.value;
  };
  const openSidebar = ref(false);
  const windowWidth =  ref(window.innerWidth)
  window.addEventListener('resize', updateWindowWidth);
  function updateWindowWidth() {
    windowWidth.value = window.innerWidth;
    if (windowWidth.value < 760) {}
    this.isSidebarOpen = this.windowWidth >= 760;
  }
</script>
<style scoped>
.side-bar{
  position: fixed;
  top: 0;
  left: 0;
  width: 18%;
  height: 100%;
  background-color: #1E212D;
  box-shadow: 7px 0 7px rgba(0, 0, 0, 0.1);
}
.logo-side{
  padding: 30px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center !important;
}
.logo-side img{
  width: 30%;
  border-radius: 50%;
}
.menu{
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: start;
  cursor: pointer;
}
.menu-item{
  margin: 14px 30px;
}
.menu-item a {
  text-decoration: none;
  color: white;
}
.menu li:hover a{
  color: #FF6F3C;
}
.menu li:hover{
  color: #FF6F3C;
}
.menu-drop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu-item-drop{
  margin: 10px 40px;
}
.side-bar-hamburger{
  position: absolute;
  top: 0;
  margin: 20px;
  font-size: 1.2rem;
  display: flex;
  justify-content: start;
  align-items: center!important;
  color:white;
  cursor: pointer;
}
.side-bar-x{
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px;
  font-size: 1.2rem;
  display: flex;
  justify-content: start;
  align-items: center!important;
  cursor: pointer;
}
@media screen and (max-width: 992px) {
  .side-bar{
    width: 25%;
  }
}
@media screen and (max-width: 760px) {
  .side-bar{
    width: 50%;
    font-size: 1rem;
  }
}
</style>