<template>
  <div class="topbar-container">
        <font-awesome-icon :icon="['fas', 'door-open']" class="icon" @click="logout"/>
  </div>
</template>
<script setup>
import store from "@/store";
import { useToast } from "vue-toastification";
import router from "@/router";
const toast = useToast();
const logout = () => {
  store.dispatch('logout').then((res) => {
    toast.success(res, {
      position: "top-right",
      timeout: 2000,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      draggable: true,
      draggablePercent: 0.32,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false
    });
    setTimeout(() => {
      router.push('/');
    }, 3000);
  });
}
</script>
<style scoped>
.topbar-container{
  width: 100%;
  height: 4rem;
  background-color: #1E212D;
  box-shadow: 7px 0 7px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: end;
  align-items: center;
}
.icon{
  font-size: 1.5rem;
  cursor: pointer;
  padding: 14px;
}
.icon:hover{
  color: #FF6F3C;
}
</style>