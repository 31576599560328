<template>
  <div class="category-container">
    <div class="route-name">
      <h4>{{route.name}}</h4>
    </div>
    <div class="category-add">
      <div class="category-add-h4">
        <h3>Ad:</h3>
      </div>
      <div class="category-add-input">
        <input class="input-class" type="text" v-model="category.name">
      </div>
      <div class="category-add-btn">
        <button class="btn-ekle" @click="updateCategory()">Güncelle</button>
      </div>
    </div>
  </div>
</template>
<script setup>
import {useRoute} from "vue-router";
import {onMounted, ref} from "vue";
import { useToast } from "vue-toastification";
const toast = useToast();
import axios from "axios";
import store from "@/store";
import router from "@/router";
const route = useRoute();
const category = ref({
  id:'',
  name:'',
  slug:''
})
async function getCategory() {
  await axios.get(`https://service.abdullahemekelektrik.com/api/v1/categories/${route.params.id}`,{
    headers : {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then(response => {
    category.value = []
    console.log(response.data);
    category.value.id = response.data.id;
    category.value.name = response.data.name;
    category.value.slug = response.data.slug;
  }).catch(error => {
    toast.error('Bir Hata Oluştu', {
      position: "top-right",
      timeout: 2000,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      draggable: true,
      draggablePercent: 0.32,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false
    })
    router.push('/category');
  });
}
function updateCategory(){
  let formData = new FormData();
  formData.append("name", category.value.name);
  formData.append("_method", 'PUT');
  axios.post(`https://service.abdullahemekelektrik.com/api/v1/categories/${route.params.id}`,formData,{
    headers : {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then(response => {
    getCategory()
    toast.success(response.data, {
      position: "top-right",
      timeout: 2000,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      draggable: true,
      draggablePercent: 0.32,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false
    })
  }).catch(error => {
    toast.error('Bir Hata Oluştu', {
      position: "top-right",
      timeout: 2000,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      draggable: true,
      draggablePercent: 0.32,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false
    })
  });
}
onMounted(() => {
  getCategory();
});
</script>
<style scoped>
.category-container{
  color: black;
  height: 100%;
}
.route-name{
  display: flex;
  color: #1f3660;
  margin: 0 10px;
}
.category-add{
  z-index: 1000;
  margin: 0 10px;
  height: auto;
}
.category-add-h4{
  display: flex;
  justify-content: start;
  align-items: center;
}
.category-add-input{
  display: flex;
  justify-content: start;
  align-items: center;
}
.category-add-btn{
  display: flex;
  justify-content: end;
  align-items: center;
}
.input-class{
  width: 100%;
  height:2rem;
  text-align: center;
}
.btn-ekle{
  display: flex;
  justify-content: center;
  align-items: center;
  height:2.4rem;
  width: 5rem;
  margin: 14px 0;
  background-color: #01204E;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>