import { createStore } from 'vuex';
import axios from "axios";
import router from "@/router";
import {ref} from "vue";

const store = createStore({
    state: {
        user: null,
        token: null,
        grant_type: 'password',
        scope: '',
        client_id: 2,
        client_secret: 'lqfXOINdZ1OPR428H2NNG2z546ADtHeSqYImQjod'
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setToken(state, token) {
            state.token = token;
        }
    },
    actions: {
        refreshUser({ commit }) {
            let isToken = localStorage.getItem('token');
            let isUser = localStorage.getItem('user');
                commit('setToken', isToken);
                commit('setUser', isUser);
            axios.get('https://service.abdullahemekelektrik.com/api/v1/auth-user',{
                headers: {
                    Authorization: `Bearer ${store.state.token}`
                }
            }).then(response => {
            }).catch(error => {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
            });
        },
        async login({ commit }, { loginFormData }) {
            await axios.post('https://service.abdullahemekelektrik.com/oauth/token', loginFormData).then(response => {
                commit('setToken', response.data.access_token);
                localStorage.setItem('token',response.data.access_token);
            }).catch(error => {
                return Promise.reject(error);
            });
            await axios.get('https://service.abdullahemekelektrik.com/api/v1/auth-user',{
                headers: {
                    Authorization: `Bearer ${store.state.token}`
                }
            }).then(response => {
                commit('setUser', response.data);
                localStorage.setItem('user', JSON.stringify(response.data));
            }).catch(error => {
                return Promise.reject(error);
            });
            return Promise.resolve('Giriş Başarılı!',200);
        },
        logout({ commit }) {
            commit('setUser', null);
            commit('setToken', null);
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            return Promise.resolve('Çıkış Başarılı!',200);
        }
    },
    modules: {}
});

export default store;