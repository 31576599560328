import { createRouter, createWebHistory } from 'vue-router';
import store from "@/store";
import SingIn from '../View/SingIn.vue';
import Dashboard from '../View/Dashboard.vue';
import NotFound from '../View/NotFound.vue';
import CategoryList from "@/View/Categories/CategoryList.vue";
import NewCategory from "@/View/Categories/NewCategory.vue";
import EditCategory from "@/View/Categories/EditCategory.vue";
import SubCategoryList from "@/View/SubCategories/SubCategoryList.vue";
import NewSubCategory from "@/View/SubCategories/NewSubCategory.vue";
import EditSubCategory from "@/View/SubCategories/EditSubCategory.vue";
import TaxList from "@/View/Tax/TaxList.vue";
import NewTax from "@/View/Tax/NewTax.vue";
import BrandList from "@/View/Brand/BrandList.vue";
import NewBrand from "@/View/Brand/NewBrand.vue";
import ProductList from "@/View/Product/ProductList.vue";
import NewProduct from "@/View/Product/NewProduct.vue";
import EditProduct from "@/View/Product/EditProduct.vue";
import ProductImageList from "@/View/ProductImage/ProductImageList.vue";
import NewImage from "@/View/ProductImage/NewImage.vue";

const routes = [
    {
        path: '/',
        name: 'SingIn',
        component: SingIn,
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta:{auth:true}
    },
    //Kategori
    {
        path: '/category',
        name: 'Kategoriler',
        component: CategoryList,
        meta:{auth:true}
    },
    {
        path: '/new-category',
        name: 'Yeni Kategori',
        component: NewCategory,
        meta:{auth:true}
    },
    {
        path: '/edit-category/:id',
        name: 'Edit Kategori',
        component: EditCategory,
        meta:{auth:true}
    },
    //Alt Kategori
    {
        path: '/sub-category',
        name: 'Alt Kategoriler',
        component: SubCategoryList,
        meta:{auth:true}
    },
    {
        path: '/new-sub-category',
        name: 'Yeni Alt Kategori',
        component: NewSubCategory,
        meta:{auth:true}
    },
    {
        path: '/edit-sub-category/:id',
        name: 'Edit Alt Kategori',
        component: EditSubCategory,
        meta:{auth:true}
    },
    //Vergi Dilimleri
    {
        path: '/tax',
        name: 'Vergi Dilimleri',
        component: TaxList,
        meta:{auth:true}
    },
    {
        path: '/new-tax',
        name: 'Yeni Vergi Dilimi',
        component: NewTax,
        meta:{auth:true}
    },
    //Marka
    {
        path: '/brand',
        name: 'Markalar',
        component: BrandList,
        meta:{auth:true}
    },
    {
        path: '/new-brand',
        name: 'Yeni Marka',
        component: NewBrand,
        meta:{auth:true}
    },
    //Product
    {
        path: '/product',
        name: 'Ürünler',
        component: ProductList,
        meta:{auth:true}
    },
    {
        path: '/new-product',
        name: 'Yeni Ürün',
        component: NewProduct,
        meta:{auth:true}
    },
    {
        path: '/edit-product/:id',
        name: 'Edit Ürün',
        component: EditProduct,
        meta:{auth:true}
    },
    {
        path: '/product-image/:id',
        name: 'Ürün İmage',
        component: ProductImageList,
        meta:{auth:true}
    },
    {
        path: '/new-product-image/:id',
        name: 'Yeni Ürün İmage',
        component: NewImage,
        meta:{auth:true}
    },

    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound,
    }
];
const router = createRouter({
    history: createWebHistory(),
    routes
});
router.beforeEach((to, from, next) => {
    const user = store.state.user
    const auth = to.meta.auth
    if (auth){
        if (!user) {
            next('/');
        } else {
            let toPath = to.path
            if (toPath === '/' && user) {
                next('/dashboard');
            }else{
                next();
            }
        }
    }else{
        let toPath = to.path
        if (toPath === '/' && user) {
            next('/dashboard');
        }else{
            next();
        }
    }
});
export default router;