<template>
  <div class="container">
    <div class="route-name">
      <h4>{{route.name}}</h4>
    </div>
    <div class="list">
      <div class="search-edit-box">
        <input type="text" placeholder="Search @ad" v-model="searchValue">
        <router-link to="/new-tax" tag="button" class="button-link">+</router-link>
      </div>
      <EasyDataTable
          :headers="headers"
          :items="items"
          :loading="loading"
          table-class-name="customize-table"
          header-text-direction="center"
          body-text-direction="center"
          hide-footer
          :search-field="searchField"
          :search-value="searchValue"
      >
      <template #item-operation="item">
        <div class="operation-wrapper">
          <font-awesome-icon class="icon" :icon="['fas', 'trash']" @click="showDialog(item)"/>
          <ConfirmDialog
              :visible="isDialogVisible"
              message="Silinen Veri Geri Getirilemez! Devam Etmek İstiyor Musunuz?"
              @confirm="deleteItem"
              @cancel="hideDialog"
          />
        </div>
      </template>
      </EasyDataTable>
      <div class="datatable-footer">
        <div class="paginate-side">
          <div class="paginate-item"><font-awesome-icon v-if="paginated.current_page === 1" class="paginate-icon" :icon="['fas', 'minus']" /><font-awesome-icon v-if="paginated.current_page !== 1" class="paginate-icon" :icon="['fas', 'square-caret-left']" @click="changePage('prev')"/></div>
          <div class="paginate-item"> {{paginated.current_page}} / {{paginated.last_page}}</div>
          <div class="paginate-item"> <font-awesome-icon v-if="paginated.last_page !== paginated.current_page" class="paginate-icon" :icon="['fas', 'square-caret-right']" @click="changePage('next')"/> <font-awesome-icon v-if="paginated.last_page === paginated.current_page" class="paginate-icon" :icon="['fas', 'minus']" /></div>
        </div>
        <div class="paginate-side">
          <div class="paginate-item"> {{paginated.from}} ~ {{paginated.to}} of {{paginated.total}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {useRoute} from "vue-router";
import EasyDataTable  from "vue3-easy-data-table";
import { useToast } from "vue-toastification";
import axios from "axios";
import {onMounted, ref, watch} from "vue";
import store from "@/store";
import router from "@/router";
import ConfirmDialog from './../../components/ConfirmDialog.vue';

const route = useRoute();
const toast = useToast();
const headers = ref([
  { text: "#", value: "id"},
  { text: "Ad", value: "name",width: 200},
  { text: "Oran", value: "percantage",width: 200},
  { text: "Operation", value: "operation"}
]);
const items = ref([]);
const isDialogVisible = ref(false);
const loading = ref(false);
const paginated = ref({
  last_page: 3,
  per_page: 10,
  total: 27,
  current_page: 1,
  from: 1,
  to: 10
});
const searchField = ref("name");
const searchValue = ref("");
const selectedData = ref();
const showDialog = (i) => {
  selectedData.value = i
  isDialogVisible.value = true;
};
const hideDialog = () => {
  isDialogVisible.value = false;
};
const deleteItem = () => {
  isDialogVisible.value = false;
  deleteData(selectedData.value)
};

function pushEdit(i) {
}
async function getData() {
  await axios.get("https://service.abdullahemekelektrik.com/api/v1/tax?page=1",{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    items.value = []
    paginated.value.last_page = response.data.last_page;
    paginated.value.per_page = response.data.per_page;
    paginated.value.total = response.data.total;
    paginated.value.current_page = response.data.current_page;
    paginated.value.from = response.data.from;
    paginated.value.to = response.data.to;
    for (let tax of response.data.data) {
      items.value.push({
        id: tax.id,
        name: tax.name,
        percantage: tax.percantage,
      })
    }
  });
}
function deleteData(i) {
  axios.delete(`https://service.abdullahemekelektrik.com/api/v1/tax/${i.id}`,{
    headers : {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then(response => {
    toast.success(response.data, {
      position: "top-right",
      timeout: 2000,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      draggable: true,
      draggablePercent: 0.32,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false
    })
    getData()
  }).catch(error => {
    toast.error('Bir Hata Oluştu', {
      position: "top-right",
      timeout: 2000,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      draggable: true,
      draggablePercent: 0.32,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false
    })
  })
}
function changePage(e) {
  let param = null
  if (e === 'next') {
    param = paginated.value.current_page + 1
  }else{
    param = paginated.value.current_page - 1;
  }
  axios.get(`https://service.abdullahemekelektrik.com/api/v1/tax?page=${param}`,{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    items.value = [];
    paginated.value.last_page = response.data.last_page;
    paginated.value.per_page = response.data.per_page;
    paginated.value.total = response.data.total;
    paginated.value.current_page = response.data.current_page;
    paginated.value.from = response.data.from;
    paginated.value.to = response.data.to;
    for (let tax of response.data.data) {
      items.value.push({
        id: tax.id,
        name: tax.name,
        percantage: tax.percantage,
      })
    }
  })
}
onMounted(() => {
  getData();
});
</script>
<style scoped>
.container{
  color: black;
  height: 100%;
}
.route-name{
  display: flex;
  color: #1f3660;
  margin: 0 10px;
}
.list{
  height: 100vh;
  margin:0 20px;
  z-index: 1000;
}
.icon{
  font-size: 1.1rem;
  margin: 5px;
  cursor: pointer;
}
.datatable-footer{
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 1.1rem;
}
.paginate-side{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.paginate-item{
 margin: .5rem;
  display: flex;
  align-items: center
}
.paginate-icon{
  font-size: 1.4rem;
  cursor: pointer;
}
.search-edit-box{
  display: flex;
  align-items: center;
  justify-content: end;
  margin: .5rem 0;
}
.button-link{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12%;
  height: 2rem;
  background-color: #01204E;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}
.search-edit-box input{
  text-align: center;
  width: 20%;
  height: 1.6rem;
  margin: 0 10px;
}
.customize-table{
  --easy-table-header-font-size: 14px;
  --easy-table-header-font-color: #c1cad4;
  --easy-table-header-background-color: #2d3a4f;
  --easy-table-header-item-padding: 10px 15px;
  --easy-table-body-even-row-font-color: #fff;
  --easy-table-body-even-row-background-color: #4c5d7a;
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;
  --easy-table-body-item-padding: 10px 15px;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;
}
</style>