<template>
  <div class="container">
    <div class="route-name">
      <h4>{{route.name}}</h4>
    </div>
    <div class="add">
      <div class="add-h4">
          <h3>İmage:</h3>
      </div>
      <div class="img-upload-area">
        <div class="input-div">
          <input class="input-img" @change="onFileChange" type="file" ref="changeId" accept="image/png, image/jpeg" />
          <button @click="openFileInput" class="input-img-btn">
            <font-awesome-icon :icon="['fas', 'cloud-arrow-up']" />
          </button>
        </div>
        <div class="upload-div" v-show="uploadImg">
          <img :src="uploadImg" alt="upload-img" class="upload-img" v-show="uploadImg" @click="rmImg">
        </div>
      </div>
      <div class="add-btn">
        <button class="btn-ekle" @click="addData()">Ekle</button>
      </div>
    </div>
  </div>
</template>
<script setup>
import {useRoute} from "vue-router";
import {onMounted, ref} from "vue";
import { useToast } from "vue-toastification";
import axios from "axios";
import store from "@/store";
import router from "@/router";
const toast = useToast();
const route = useRoute();
const uploadImg = ref();
const changeId = ref();
const image1 = ref();

function addData(){
  let formData = new FormData();
  formData.append("product_id", route.params.id);
  if (image1.value) {
    formData.append('image1',image1.value);
  }
  axios.post('https://service.abdullahemekelektrik.com/api/v1/product_image',formData,{
    headers : {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then(response => {
    toast.success(response.data, {
      position: "top-right",
      timeout: 2000,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      draggable: true,
      draggablePercent: 0.32,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false
    })
    setTimeout(() => {
      router.push(`/product-image/${route.params.id}`);
    }, 3000);
  }).catch(error => {
    toast.error('Bir Hata Oluştu', {
      position: "top-right",
      timeout: 2000,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      draggable: true,
      draggablePercent: 0.32,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false
    })
  });
}
function onFileChange(event){
  const file = event.target.files[0];
  image1.value = event.target.files[0]
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      uploadImg.value = e.target.result;
    };
    reader.readAsDataURL(file);
  }
}
function openFileInput(){
  changeId.value.click()
}
function rmImg(){
  uploadImg.value = ''
  image1.value = ''
  changeId.value.value = ''
}
function delSelectInput(e) {
  if (e === 'product_id'){
    product_id.value = null
  }
}
onMounted(() => {
});

</script>
<style scoped>
.container{
  color: black;
  height: 100%;
}
.route-name{
  display: flex;
  color: #1f3660;
  margin: 0 10px;
}
.add{
  z-index: 1000;
  margin: 0 10px;
  height: auto;
}
.add-h4{
  display: flex;
  justify-content: start;
  align-items: center;
}
.add-input{
  display: flex;
  justify-content: start;
  align-items: center;
}
.add-btn{
  display: flex;
  justify-content: end;
  align-items: center;
}
.input-class{
  width: 100%;
  height:2rem;
  text-align: center;
}
.btn-ekle{
  display: flex;
  justify-content: center;
  align-items: center;
  height:2.4rem;
  width: 5rem;
  margin: 14px 0;
  background-color: #01204E;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.img-upload-area{
  width: 100%;
  height: 25rem;
  display: flex;
}
.input-div{
  width: 50%;
  height: 20rem;
  display: flex;
}
.upload-div{
  width: 50%;
  height: 20rem;
  display: flex;
}
.input-img{
  display: none;
  visibility: hidden;
}
.input-img-btn{
  height: 2.4rem;
  border: none;
  border-radius: 5px;
  background-color: #01204E;
  color: white;
  width: 100%;
  cursor: pointer;
}
.upload-img{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 1.5rem;
  cursor: pointer;
}
@media screen and (max-width: 992px) {
  .img-upload-area{
    display: flex;
    flex-direction: column;
    width: 100%;
    height:auto;
  }
  .input-div{
    width: 100%;
    height: 2.5rem;
  }
  .upload-div{
    width: 100%;
    height: 20rem;
  }
  .upload-img{
    box-sizing: border-box;
    padding: 1.5rem 0;
  }
}
</style>