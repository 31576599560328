<template>
  <div class="main">
    <div class="contain">
      <div class="header">
        <div>
          <img src="https://service.abdullahemekelektrik.com/emekelektrik.jpeg" alt="" class="logo">
        </div>
        <div>
          <h4>Abdullah Emek Elektrik</h4>
        </div>
      </div>
      <div class="content">
        <div class="form">
          <form>
              <h5>Eposta:</h5>
              <input type="email" v-model="loginFormData.username">
              <h5>Şifre:</h5>
              <input type="password" v-model="loginFormData.password">
          </form>
          </div>
        <div class="button">
            <button class="reset">Şifremi Sıfırla</button>
            <button class="login" @click="login">Giriş</button>
          </div>
      </div>
      <div class="footer"></div>
    </div>
  </div>
</template>
<script setup>
  import store from "@/store";
  import { useToast } from "vue-toastification";
  import router from "@/router";
  const toast = useToast();
  const loginFormData = {
    username:'',
    password:'',
    grant_type: 'password',
    scope: '',
    client_id: 2,
    client_secret: 'lqfXOINdZ1OPR428H2NNG2z546ADtHeSqYImQjod'
  }
  async function login() {
    await store.dispatch("login",{loginFormData}).then(response=>{
        toast.success(response, {
          position: "top-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.32,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false
        })
      setTimeout(() => {
        router.push('/dashboard');
      }, 3000);
    }).catch(error => {
      toast.error(error.message, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.32,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false
      })
    })
  }
</script>
<style scoped>
  .main{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #20232c;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contain {
    width: 32%;
    height: 60%;
    background-color: white;
    color: black;
  }
  .header{
    width: 100%;
    height: 15%;
    background-color: #1f3660;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
  }
  .content{
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    text-align: start;
  }
  .footer{
    width: 100%;
    height: 15%;
    background-color: #1f3660;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
  }
  .logo{
    width: 50px;
  }
  .form{
    padding: 50px 60px 50px 60px;
    opacity: .7;
    line-height: 1px;
  }
  .form input {
    width: 100%;
    height: 35px;
    box-sizing: border-box;
  }
  .button{
    display: flex;
    padding: 0px 60px 0px 60px;
    justify-content: space-between;
  }
  .button button{
    width: 48%;
    height: 30px;
    border: none;
    cursor: pointer;
    color: white;
  }
  .reset{
    background-color: #0881ba;
  }
  .login{
    background-color: #8d9de2;
  }

  @media (max-width: 992px) {
    .contain{
      width: 80%;
    }
  }
</style>