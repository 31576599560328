<template>
  <div class="dashboard-container">
    <div class="route-name">
      <h4>{{route.name}}</h4>
    </div>
    <div class="dashboard-row">
      <div class="dashboard-card">
        <div>
          <font-awesome-icon :icon="['fas', 'folder']" />
        </div>
        <div>
          <span>
            Kategoriler
          </span>
        </div>
        <div>
          10
        </div>
      </div>
      <div class="dashboard-card">
        <div>
          <font-awesome-icon :icon="['fas', 'folder']" />
        </div>
        <div>
          <span>
            Alt Kategoriler
          </span>
        </div>
        <div>
          7
        </div>
      </div>
      <div class="dashboard-card">
        <div>
          <font-awesome-icon :icon="['fas', 'folder']" />
        </div>
        <div>
          <span>
            Markalar
          </span>
        </div>
        <div>
          21
        </div>
      </div>
      <div class="dashboard-card">
        <div>
          <font-awesome-icon :icon="['fas', 'folder']" />
        </div>
        <div>
          <span>
            Vergi Dilimi
          </span>
        </div>
        <div>
          10
        </div>
      </div>
    </div>
    <div class="dashboard-row">
      <div class="dashboard-card">
        <div>
          <font-awesome-icon :icon="['fas', 'folder']" />
        </div>
        <div>
          <span>
            Ürünler
          </span>
        </div>
        <div>
          10
        </div>
      </div>
      <div class="dashboard-card">
        <div>
          <font-awesome-icon :icon="['fas', 'folder']" />
        </div>
        <div>
          <span>
            Kullanıcılar
          </span>
        </div>
        <div>
          7
        </div>
      </div>
      <div class="dashboard-card">
        <div>
          <font-awesome-icon :icon="['fas', 'folder']" />
        </div>
        <div>
          <span>
            Güncel Sepet
          </span>
        </div>
        <div>
          21
        </div>
      </div>
      <div class="dashboard-card">
        <div>
          <font-awesome-icon :icon="['fas', 'folder']" />
        </div>
        <div>
          <span>
            Satışlar
          </span>
        </div>
        <div>
          10
        </div>
      </div>
    </div>
    <div>
      <h4>Hızlı Erişim</h4>
      <div class="dashboard-row">
        <div class="dashboard-card">
          <div>
            <font-awesome-icon :icon="['fas', 'link']" />
          </div>
          <div>
          <span>
            <router-link tag="a" to="/new-category">Kategoriler Ekle</router-link>
          </span>
          </div>
        </div>
        <div class="dashboard-card">
          <div>
            <font-awesome-icon :icon="['fas', 'link']" />
          </div>
          <div>
          <span>
            <router-link tag="a" to="/new-sub-category">Alt Kategoriler Ekle</router-link>
          </span>
          </div>
        </div>
        <div class="dashboard-card">
          <div>
            <font-awesome-icon :icon="['fas', 'link']" />
          </div>
          <div>
          <span>
            <router-link tag="a" to="/new-brand">Markalar Ekle</router-link>
          </span>
          </div>
        </div>
        <div class="dashboard-card">
          <div>
            <font-awesome-icon :icon="['fas', 'link']" />
          </div>
          <div>
          <span>
            <router-link tag="a" to="/new-tax">Vergi Dilimi Ekle</router-link>
          </span>
          </div>
        </div>
      </div>
      <div class="dashboard-row">
        <div class="dashboard-card">
          <div>
            <font-awesome-icon :icon="['fas', 'link']" />
          </div>
          <div>
          <span>
            <router-link tag="a" to="/new-product">Ürünler Ekle</router-link>
          </span>
          </div>
        </div>
        <div class="dashboard-card">
          <div>
            <font-awesome-icon :icon="['fas', 'link']" />
          </div>
          <div>
          <span>
            <router-link tag="a" to="/">Kullanıcı Liste</router-link>
          </span>
          </div>
        </div>
        <div class="dashboard-card">
          <div>
            <font-awesome-icon :icon="['fas', 'link']" />
          </div>
          <div>
          <span>
            <router-link tag="a" to="/">Güncel Sepet Liste</router-link>
          </span>
          </div>
        </div>
        <div class="dashboard-card">
          <div>
            <font-awesome-icon :icon="['fas', 'link']" />
          </div>
          <div>
          <span>
            <router-link tag="a" to="/">Satış Liste</router-link>
          </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {useRoute} from "vue-router";
const route = useRoute();
</script>
<style scoped>
.dashboard-container{
  color: black;
  height: auto;
  overflow: auto;
}
.dashboard-row{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}
.dashboard-card{
  display: flex;
  background-color: #FF6F3C;;
  width: 25%;
  height: 80px;
  justify-content: space-evenly;
  align-items: center;
  border: 3px solid #1E212D;
  border-radius: 10px;
  margin: 10px;
  font-weight: 600;
}
.route-name{
  display: flex;
  color: #1f3660;
  margin: 0 10px;
}
a {
  color: black;
  text-decoration: none;
  font-weight: bold;
}
@media screen and (max-width: 760px) {
 .dashboard-row{
   flex-direction: column;
 }
 .dashboard-card{
  width: 80%;
 }
}
</style>