<template>
  <div class="confirm-dialog" v-if="visible">
    <div class="confirm-dialog-overlay" @click="cancel"></div>
    <div class="confirm-dialog-content">
      <p>{{ message }}</p>
      <button @click="cancel">İptal</button>
      <button @click="confirm">Evet</button>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ConfirmDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: 'Are you sure?'
    },
  },
  emits: ['confirm', 'cancel'],
  setup(props, { emit }) {
    const confirm = () => {
      emit('confirm');
    };
    const cancel = () => {
      emit('cancel');
    };

    return {
      confirm,
      cancel
    };
  }
});
</script>

<style scoped>
.confirm-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
  backdrop-filter: blur(1px) !important;
  transition: all 0.5s ease;
}

.confirm-dialog-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.confirm-dialog-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 1;
  text-align: center;
  border: 1px solid #01204E;
  font-size: 1rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

button {
  margin: 5px;
  cursor: pointer;
  width: 5rem;
  height: 2rem;
  color: white;
  background-color: #01204E;
  border: none;
  border-radius: 5px;
}
</style>