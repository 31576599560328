<template>
  <div class="main">
    <div class="topbar" v-if="route.name !== 'SingIn' && route.name !== 'NotFound'">
      <TopBar></TopBar>
    </div>
    <div class="sideBar" v-if="route.name !== 'SingIn' && route.name !== 'NotFound'">
      <SideBar></SideBar>
    </div>
    <div class="side">
      <RouterView></RouterView>
    </div>
  </div>
</template>
<script setup>
import {ref} from "vue";
import router from "@/router";
import { useToast } from "vue-toastification";
import {useRoute} from "vue-router";
const route = useRoute();
const toast = useToast();
import SideBar from "@/components/SideBar.vue";
import store from "@/store";
import TopBar from "@/components/TopBar.vue";
const token = store.dispatch("refreshUser")

const notify = () => {
  toast.success("Giriş Başarılı!", {
    position: "top-right",
    timeout: 2000,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    draggable: true,
    draggablePercent: 0.32,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false
  });
};

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.main{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  color: white;
  z-index: 1000;
  overflow: auto;
}
.side{
  position: absolute;
  top: 5rem;
  left: 19%;
  width: 80%;
  height: auto;
  z-index: 1000;
  overflow: auto;
}
@media screen and (max-width: 992px) {
  .side{
    position: absolute;
    top: 5rem;
    left: 27%;
    width: 70%;
    height: auto;
    z-index: 0;
  }
}
@media screen and (max-width: 760px) {
  .side{
    position: absolute;
    top: 5rem;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -1000;
  }
}
</style>
