<template>
  <div class="container">
    <div class="route-name">
      <h4>{{route.name}}</h4>
    </div>
    <div class="add">
      <div class="add-h4">
        <h3>Ad:</h3>
      </div>
      <div class="add-input">
        <input class="input-class" type="text" v-model="subCategory.name">
      </div>
      <div class="add-h4">
        <h3>Kategori:</h3>
      </div>
      <div class="add-input">
        <select class="input-class select-input" v-model="subCategory.category_id">
          <option v-for="category in categories" :key="category.id" :value="category.id">{{category.name}}</option>
        </select>
      </div>
      <div class="add-btn">
        <button class="btn-ekle" @click="updateData()">Güncelle</button>
      </div>
    </div>
  </div>
</template>
<script setup>
import {useRoute} from "vue-router";
import {onMounted, ref} from "vue";
import { useToast } from "vue-toastification";
import axios from "axios";
import store from "@/store";
import router from "@/router";

const toast = useToast();
const route = useRoute();
const subCategory = ref({
  name : '',
  slug : '',
  category_id : '',
  category_name : '',
})
const categories = ref()
async function getData() {
  await axios.get(`https://service.abdullahemekelektrik.com/api/v1/sub_categories/${route.params.id}`,{
    headers : {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then(response => {
    subCategory.value = []
    subCategory.value.name = response.data.name;
    subCategory.value.slug = response.data.slug;
    subCategory.value.category_id = response.data.category_id;
    subCategory.value.category_name = response.data.categories.name;
  }).catch(error => {
    toast.error('Bir Hata Oluştu', {
      position: "top-right",
      timeout: 2000,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      draggable: true,
      draggablePercent: 0.32,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false
    })
    router.push('/sub-category');
  });
}
async function getCategories() {
  await axios.get("https://service.abdullahemekelektrik.com/api/v1/categories?no_page",{
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then((response) => {
    categories.value = response.data
  });
}
function updateData(){
  let formData = new FormData();
  formData.append("name", subCategory.value.name);
  formData.append("category_id", subCategory.value.category_id);
  formData.append("_method", 'PUT');
  axios.post(`https://service.abdullahemekelektrik.com/api/v1/sub_categories/${route.params.id}`,formData,{
    headers : {
      Authorization: `Bearer ${store.state.token}`
    }
  }).then(response => {
    getData()
    toast.success(response.data, {
      position: "top-right",
      timeout: 2000,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      draggable: true,
      draggablePercent: 0.32,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false
    })
  }).catch(error => {
    toast.error('Bir Hata Oluştu', {
      position: "top-right",
      timeout: 2000,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      draggable: true,
      draggablePercent: 0.32,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false
    })
  });
}
onMounted(() => {
  getData();
  getCategories();
});
</script>
<style scoped>
.container{
  color: black;
  height: 100%;
}
.route-name{
  display: flex;
  color: #1f3660;
  margin: 0 10px;
}
.add{
  z-index: 1000;
  margin: 0 10px;
  height: auto;
}
.add-h4{
  display: flex;
  justify-content: start;
  align-items: center;
}
.add-input{
  display: flex;
  justify-content: start;
  align-items: center;
}
.add-btn{
  display: flex;
  justify-content: end;
  align-items: center;
}
.input-class{
  width: 100%;
  height:2rem;
  text-align: center;
}
.btn-ekle{
  display: flex;
  justify-content: center;
  align-items: center;
  height:2.4rem;
  width: 5rem;
  margin: 14px 0;
  background-color: #01204E;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.select-input{
  min-height: 2.8rem;
}
</style>