import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'; // router dosyanızın yolunu doğru şekilde belirtin
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import CKEditor from '@ckeditor/ckeditor5-vue'

library.add(fas);
const app= createApp(App);
app.use(router);
app.use(Toast);
app.use( CKEditor )
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('EasyDataTable', Vue3EasyDataTable);
app.config.globalProperties.$axios = axios;
app.mount('#app');
